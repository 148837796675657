<template>

  <div>


    <!--loading state-->
    <template v-if="isLoading">
      <div class="text-center">
        <b-spinner variant="primary" label="Loading" />
      </div>
    </template>

    <template v-else>


      <b-progress v-if="isMailInProgress" animated value="100" variant="primary" class="progress-bar-primary"/>

      <!--cabecera solicitud -->
      <b-card no-body>

        <b-card-header class="align-items-baseline pb-1">
          <div>

            <b-card-title class="mb-25">
              <!-- back option -->
              <span @click="handleCloseForm">
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="20"
                  class="align-bottom  cursor-pointer"
                />
              </span>
              {{ formType.ftyNombre }}
            </b-card-title>

            <b-card-text class="mb-0">
              {{ formType.ftyCodigo }} {{ formType.ftyVersion }}
            </b-card-text>

          </div>

          <div>

            <!-- latest status -->

            <template v-if="isFormCancelled">
              <b-badge
                pill
                variant="light-danger" class="mr-2">Anulada</b-badge>
            </template>

            <template v-else>
              <b-badge
                pill
                :variant="getLightFormStatusVariant"
                class="mr-2 text-uppercase"
              >
                {{ getFormStatusName }}
              </b-badge>
            </template>


            <!-- menu vertical-->

            <b-dropdown
              variant="link"
              no-caret
              class="chart-dropdown"
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="text-body cursor-pointer"
                />
              </template>

              <b-dropdown-item @click="handleCancelForm" :disabled="isFormCancelled">
                Anular formulario
              </b-dropdown-item>

              <template v-if="isFormCreated">
                <b-dropdown-item @click="handleSendMail" :disabled="isFormCancelled">
                  Enviar correo electrónico a colaborador
                </b-dropdown-item>
              </template>

            </b-dropdown>

          </div>
        </b-card-header>

        <!-- info basica-->
        <b-row class="p-1">
          <!-- datos del colaborador -->
          <b-col md="8" sm="12">

            <b-form-group label="Colaborador">
              <v-select
                v-model="formLocal.empId"
                label="fullName"
                :options="employees"
                :reduce="option => option.empId"
                :clearable="false"
                :disabled="isReadOnly || isBusy"
                @input="handleUpdateColaborador"
              />
            </b-form-group>


            <dl>
              <dt>Oficina</dt>
              <dd>{{employeeData.offNombre}}</dd>
              <dt>Area | Cargo</dt>
              <dd>{{employeeData.secNombre}}. {{employeeData.posNombre}}</dd>
              <dt>Correo electrónico</dt>
              <dd>{{employeeData.empEmail}}</dd>
            </dl>

          </b-col>

          <!-- datos del formulario -->
          <b-col md="4" sm="12">
            <!-- Número secuencial -->
            <b-form-group label="Secuencial">
              <!-- button on right -->
              <b-input-group class="input-group-merge disabled">
                <b-input-group-prepend is-text>
                  <feather-icon icon="HashIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="invoice-data-id"
                  v-model="formLocal.frmSecuencial"
                  disabled
                />
              </b-input-group>



            </b-form-group>
            <!-- fecha emision formulario-->
            <b-form-group label="Fecha de formulario">
              <flat-pickr
                v-model="formLocal.frmFecha"
                class="form-control"
                :config="configDate"
                :disabled="isReadOnly || isBusy"
                @on-change="handleChangeFecha"
              />
            </b-form-group>
            <!-- fecha plazo del formulario-->
            <b-form-group label="Fecha de vencimiento">
              <flat-pickr
                ref="datepicker"
                v-model="formLocal.frmFechaLimite"
                class="form-control"
                :config="configDateTime"
                :disabled="isReadOnly || isBusy"
                @on-change="handleChangeVencimiento"
              />
            </b-form-group>
          </b-col>

        </b-row>

        <!-- save changes -->
        <b-row class="p-1" v-if="hasUnsavedChanges">
          <b-col>
            <b-button
              variant="secondary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="handleDiscardChanges"
              :disabled="isBusy"
            >
              Descartar
            </b-button>
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="handleSaveChanges"
              :disabled="isBusy"
            >
              Guardar cambios
            </b-button>

          </b-col>
        </b-row>


        <!-- errors section -->
        <template v-if="hasErrors">
          <b-row class="p-1">
            <b-col>
              <validation-errors :errors="errors"/>
            </b-col>
          </b-row>

        </template>



      </b-card>


      <template v-if="!hasUnsavedChanges">

        <b-tabs lazy>
          <!-- aplicaciones agregadas -->
          <b-tab >
            <template #title>
              <feather-icon
                icon="BoxIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Accesos</span>
            </template>




            <!-- empty state -->
            <template v-if="isEmptyApps">
              <b-card class="text-center">

                <feather-icon
                  icon="InfoIcon"
                  size="100"
                  class="mr-50 mb-3 mt-2"
                />
                <h2>No hay accesos registrados aún</h2>
                <p class="p-2">
                  Actualmente no hay accesos disponibles para mostrar.
                  Por favor, agregue un nuevo acceso utilizando el botón 'Agregar aplicación'.
                </p>
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="handleAddSystem"
                  :disabled="isBusy || isReadOnly"
                >
                  Agregar aplicación
                </b-button>
              </b-card>
            </template>


            <template v-else>
              <b-button
                v-if="isDraftForm"
                variant="primary"
                class="mb-1 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="handleAddSystem"
                :disabled="isBusy || isReadOnly"
              >
                Agregar aplicación
              </b-button>


              <!--aplicaciones asignadas-->
              <b-list-group>

                <b-list-group-item
                  class="d-flex justify-content-between"
                  v-for="(system, index) in formSystemsLocal"
                  :key="index"
                >
                  <div>
              <span class="mr-1">
                <feather-icon
                  icon="PackageIcon"
                  size="16"
                />
              </span>
                    <span>{{ system.sysNombre }}  </span>

                    <div class="d-flex flex-wrap justify-content-start align-items-center mt-1">
                      <b-badge
                        variant="light-success"
                        v-for="role in system.roles"
                        :key="role.rolId"
                        class="mr-1"
                      >
                        {{ role.rolNombre }}
                      </b-badge>
                    </div>

                    <p class="font-weight-lighter mt-1">
                      <small>{{ system.frsJustificacion }}</small>
                    </p>


                  </div>

                  <b-button
                    variant="link"
                    class="p-0 text-danger"
                    @click="handleEliminarAplicacion(system.frsId)"
                    v-if="!isReadOnly"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      size="22"
                    />
                  </b-button>


                </b-list-group-item>

              </b-list-group>

              <!-- Boton para pasar de borrador a creada-->
              <b-button
                v-if="!isReadOnly"
                variant="success"
                class="mt-1 mb-1 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="handleUpdateStatusForm"
                :disabled="isBusy || isEmptyApps"
              >
                Finalizar borrador y crear solicitud
              </b-button>
            </template>




          </b-tab>
          <!-- Documentos adjunto al formulario -->
          <b-tab :disabled="isDraftForm">
            <template #title>
              <feather-icon
                icon="PaperclipIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Documentos</span>
            </template>

            <solicitud-creacion-edit-documents
              v-if="formLocal.frmId"
              :frm-id="formLocal.frmId"
              :is-read-only="false"
            />

          </b-tab>


          <!-- Historial de actividades -->
          <b-tab :disabled="isDraftForm">
            <template #title>
              <feather-icon
                icon="MessageSquareIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Actividad</span>
            </template>

            <solicitud-creacion-edit-activity
              v-if="formLocal.frmId"
              :frm-id="formLocal.frmId"
            />

          </b-tab>
        </b-tabs>

      </template>








      <!-- modal agregar aplicacion-->
      <b-modal
        title="Asignar aplicaciones"
        v-model="showModal"
        no-close-on-backdrop
        centered
        @hidden="resetModal"
      >
        <b-form-group label="Aplicación">
          <v-select
            v-model="formModal.selectedSystem"
            label="sysNombre"
            :options="systemsOptions"
            :reduce="option => option.sysId"
            @input="updateRoles"
          />
        </b-form-group>

        <b-form-group label="Roles">
          <v-select
            v-model="formModal.selectedRoles"
            label="rolNombre"
            :options="rolesOptions"
            :reduce="option => option.rolId"
            multiple
          />
        </b-form-group>

        <b-form-group label="Justificación">
          <b-form-textarea
            rows="3"
            max-rows="8"
            no-auto-shrink
            v-model="formModal.textJustificacion"
          />
        </b-form-group>

        <template #modal-footer>
          <div class="w-100 text-right">
            <b-button
              variant="primary"
              @click="handleAgregarAplicacion"
              :disabled="isBusy || hasSomeNullEmptyItem"
            >
              <b-spinner small v-if="isBusy"/>
              Aceptar
            </b-button>
            <b-button
              variant="secondary"
              class="ml-1"
              :disabled="isBusy"
              @click="handleCerrarModal"
            >
              Cerrar
            </b-button>
          </div>
        </template>

        <validation-errors v-if="errors" :errors="errors"/>

      </b-modal>



    </template>

  </div>

</template>

<script>

import {
  BRow,
  BCol,
  BSpinner,
  BCard,
  BCardText,
  BCardTitle,
  BCardHeader,
  BFormGroup,
  BListGroup,
  BListGroupItem,
  BButton,
  BModal,
  BFormTextarea,
  BBadge,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormInput,
  BForm,
  BFormSelect,
  BIcon,
  BDropdownItem,
  BDropdown,
  BTab,
  BTabs,
  BOverlay,
  BProgress,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from "flatpickr/dist/l10n/es.js"

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ValidationErrors from '@/modules/components/ValidationErrors.vue'

import { mapActions } from 'vuex'

import { resolveFormStatusName, resolveLightFormStatusVariant } from '@/modules/common/utils'
import { FORM_TASK_CREATE } from '@/modules/common/constants'

export default {

  name: 'SolicitudCreacionEdit',

  components:{
    BDropdown,
    BDropdownItem,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroup,
    BCardHeader,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BSpinner,
    BCard,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BButton,
    BModal,
    BFormTextarea,
    BBadge,
    BForm,
    BFormSelect,
    BIcon,
    BTab,
    BTabs,
    BOverlay,
    flatPickr,
    BProgress,
    vSelect,
    ValidationErrors,
    ToastificationContent,
    SolicitudCreacionEditDocuments: () => import('@/modules/solicitante/views/solicitudes/creacion/SolicitudCreacionEditDocuments.vue'),
    SolicitudCreacionEditActivity: () => import('@/modules/solicitante/views/solicitudes/creacion/SolicitudCreacionEditActivity.vue')
  },
  directives: {
    Ripple,
  },

  data(){
    return {
      errors: [],
      isLoading: false,

      isBusy: false,


      hasUnsavedChanges: false,  // control de datos cambiados en la cabecera
      initializedData: false, // controla si los datos fueron inicializados por primera vez

      isMailInProgress: false, // controla si se ha iniciado envío de correo

      showModal: false,

      formType: [],
      employees: [],
      employeeData: [],

      formLocal:{
        empId: null,
        frmFecha: null,
        frmFechaLimite: null
      },
      formSystemsLocal: [],
      //data modal
      formModal: {
        selectedSystem: null,
        selectedRoles: [],
        textJustificacion: null
      },
      systemsOptions: [],
      rolesOptions: [],

      configDate: {
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: "D, j-F-Y",
        locale: Spanish,
      },
      configDateTime: {
        dateFormat: 'Y-m-d H:i',
        altInput: true,
        altFormat: "D, j-F-Y, H:i",
        locale: Spanish,
        enableTime: true,
      },

    }
  },

  watch: {
    'formLocal.frmFechaLimite'(){
      if ( this.initializedData )
        this.hasUnsavedChanges = true
    },
  },

  computed: {

    queryKey(){
      const { key } = this.$route.query
      return key ?? null
    },

    hasErrors(){

      return this.errors.length !== 0

    },


    //
    isEmptyApps(){
      return this.formSystemsLocal.length === 0
    },



    //determinar si la solicitud/formulario ha pasado de borrado a creada
    isFormCreated(){
      return Boolean(this.formLocal.frmEstado)  // verdadero si es "1",1
    },

    //determinar si el formulario está anulado
    isFormCancelled(){
      return this.formLocal.frmAnulado !== null
    },

    // si formulario está creado o anulado entonces no se puede modificar
    isReadOnly(){
      return this.isFormCreated || this.isFormCancelled
    },

    // verificar si formulario está en borrador para no activad tabs de ADJUNTOS Y ACTIVIDAD
    isDraftForm(){
      return !Boolean(this.formLocal.frmEstado)  // verdadero si es "0",0
    },

    // verificar si algun elemento de un objeto es NULL o vacío
    hasSomeNullEmptyItem () {
      const nullable = ( element ) => element === null || element === '' || (Array.isArray(element) && element.length === 0)
      return Object.values( this.formModal ).some( nullable )
    },

    // establecer estilo de estado actual
    getLightFormStatusVariant(){
      const [ latestStatus ] = this.formLocal.statuses
      const status = {
        status: this.formLocal.frmEstado,
        latestStatus: latestStatus ? latestStatus.fstStatus : null
      }
      return resolveLightFormStatusVariant(status)
    },

    // establecer texto del estado actual
    getFormStatusName(){
      const [ latestStatus ] = this.formLocal.statuses
      const status = {
        status: this.formLocal.frmEstado,
        latestStatus: latestStatus ? latestStatus.fstStatus : null
      }
      return resolveFormStatusName( status )
    },






  },



  methods: {

    ...mapActions('sga-module', ['findFormType']),
    ...mapActions('empleado-module', ['listEmpleados', 'find']),
    ...mapActions('aplicacion-module', ['listAplicaciones']),
    ...mapActions('role-module', ['listRolesByApp']),
    ...mapActions('solicitante-solicitudes-module',
      [
        'findOne',
        'createFormSystem',
        'updateFormularioCreacion',
        'updateStatusFormularioCreacion',
        'cancelFormularioCreacion',
        'fetchFormSystems',
        'deleteFormSystem',
        'sendEmailSolicitudColaborador'
      ]
    ),

    // datos iniciales del formulario
    async initializeReferences(){
      try {
        this.isLoading = true

        this.formType = []
        this.employees = []

        //tipo de formulario
        this.formType = await this.findFormType({ id: this.formLocal.ftyId })

        //lista de empleados
        const employees = await this.listEmpleados()
        this.employees = employees.map(employee => ({
          empId: employee.empId,
          empNombres: employee.empNombres,
          empApellidos: employee.empApellidos,
          fullName: `${employee.empNombres} ${employee.empApellidos}`
        }))


        this.isLoading = false
      } catch (error) {
        console.log("CATCH", error)
      }
    },

    // cargar formulario
    async loadData(){

      try {

        this.isBusy = true
        this.formLocal = await this.findOne({uuid: this.queryKey} )

        await this.updateColaborador()

      } catch (error) {

        console.log("CATCH LOAD DATA", error)

      } finally {
        this.isBusy = false
      }



    },

    // procesar peticion de descartar cambios
    async handleDiscardChanges(){

      this.errors = []

      await this.loadData()

      this.hasUnsavedChanges = false
      this.initializedData = true

    },

    // procesar peticion para guardar cambios
    handleSaveChanges(){
      this.handleUpdateForm()
    },


    // procesar cambio realizado en el colaborador
    async handleUpdateColaborador(){
      await this.updateColaborador()
      if ( this.initializedData ){
        this.hasUnsavedChanges = true
      }
    },

    // actualizar referencias del colaborador seleccionado
    async updateColaborador(){
      this.employeeData = await this.find({id: this.formLocal.empId})
    },

    // procesar cambio de fecha realizado
    handleChangeFecha(){

      if ( this.initializedData ){
        this.hasUnsavedChanges = true
      }
    },

    // procesar cambio de fecha vencimiento realizado
    handleChangeVencimiento(){
      if ( this.initializedData ){
        this.hasUnsavedChanges = true
      }
    },

    // actualizar datos del formulario
    async handleUpdateForm(){
      try {

        this.errors = []

        this.isBusy = true

        const dataForm = {
          frmId: this.formLocal.frmId,
          empId: this.formLocal.empId,
          frmUuid: this.formLocal.frmUuid,
          frmFecha: this.formLocal.frmFecha,
          frmFechaLimite: this.formLocal.frmFechaLimite,
        }

        await this.updateFormularioCreacion( dataForm )

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Formulario',
            icon: 'CheckIcon',
            text: 'Datos actualizados correctamente',
            variant: 'success'
          },
        })

        this.hasUnsavedChanges = false


      } catch (error) {

        if ( error.response.status === 422 ) {

          const  data  = error.response.data.errors ?? error.response.data

          console.log( "UNXPECTED", error.response.data.errors, typeof data )

          // errores de validacion
          this.errors = data

        }

      } finally {

        this.isBusy = false

      }
    },

    // actualizar por unica vez el estado del formulario de "en progreso" a "creado"
    async handleUpdateStatusForm(){

      const isConfirm = await  this.$bvModal.msgBoxConfirm(
        '¿Estás listo para finalizar el borrador? Ten en cuenta que una vez que lo hagas, el borrador se guardará como versión final y no podrás hacer más cambios en él',
        {
          title: 'Confirmar finalización de borrador',
          okVariant: 'success',
          okTitle: 'Sí, finalizar borrador',
          cancelTitle: 'No',
          cancelVariant: 'secondary',
          modalClass: "modal-success",
          hideHeaderClose: false,
          centered: true,
        })

      if ( ! isConfirm )  return

      try {

        this.initializedData = false

        this.errors = []
        this.isBusy =  true
        const dataForm = {
          frmId: this.formLocal.frmId,
          frmUuid: this.formLocal.frmUuid,
          frmEstado: 1,
          frmFecha: this.formLocal.frmFecha,
          frmFechaLimite: this.formLocal.frmFechaLimite
        }

        await this.updateStatusFormularioCreacion(dataForm)

        // actualizar estado formulario
        this.formLocal.frmEstado = 1

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Formulario',
            icon: 'CheckIcon',
            text: 'El formulario ha sido creado!',
            variant: 'success'
          },
        })

        this.initializedData = true

      } catch (error) {


        if ( error.response.status === 422 ) {

          const  data  = error.response.data.errors ?? error.response.data

          console.log( "UNXPECTED", error.response.data, data )

          // errores de validacion
          this.errors = data

        }

      } finally {

        this.isBusy = false

      }
    },

    // anular formulario
    async handleCancelForm(){

      const isConfirm = await  this.$bvModal.msgBoxConfirm(
        'Si anulas el formulario, cualquier proceso o tarea solicitada será suspendida de manera definitiva. ¿Deseas continuar?',
        {
          title: 'Anular formulario',
          okVariant: 'warning',
          okTitle: 'Sí, anular formulario',
          cancelTitle: 'No',
          cancelVariant: 'secondary',
          modalClass: "modal-warning",
          hideHeaderClose: false,
          centered: true,
        })

      if ( ! isConfirm )  return

      try {

        this.errors = []
        this.isBusy = true
        const params = {
          frmId: this.formLocal.frmId,
          frmUuid: this.formLocal.frmUuid
        }

        await this.cancelFormularioCreacion( params )

        // cerrar formulario
        this.handleCloseForm()

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Formulario anulado',
            icon: 'CheckIcon',
            text: ' El formulario ha sido anulado correctamente y todas las tareas asociadas han sido canceladas.',
            variant: 'success'
          },
        })

      } catch (error) {



      } finally {
        this.isBusy = false
      }

    },

    //
    async handleSendMail(){
      try {

        this.isMailInProgress = true

        await this.sendEmailSolicitudColaborador( { frmId: this.formLocal.frmId })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Correo enviado',
            icon: 'CheckIcon',
            text: 'El correo ha sido enviado con éxito al colaborador',
            variant: 'success'
          },
        })

      } catch (e) {

      } finally {

        this.isMailInProgress = false

      }
    },

    // cerrar formulario
    handleCloseForm(){
      this.$router.push({name: 'solicitudes-menu'})
    },


    /*
    seccion detalles
     */

    // cargar detalles
    async loadDataDetalles(){


      try {

        this.isBusy = true
        this.formSystemsLocal = await this.fetchFormSystems( { frmId: this.formLocal.frmId } )

      } catch (error) {

        console.log("CATCH LOAD DATA DETAIL", error)

      } finally {
        this.isBusy = false
      }

    },

    // manejar peticio de agregar aplicacion al formulario
    async handleAddSystem(){

      try {
        this.isBusy = true
        this.systemsOptions = []

        const systemsOptions = await this.listAplicaciones()
        // excluir sistemas ya seleccionados en el formulario
        const alreadySystems = this.formSystemsLocal.map( item => item.sysId )
        this.systemsOptions = systemsOptions.filter( option => !alreadySystems.includes(option.sysId) )

        this.showModal = true

      }catch (error) {

      }finally {
        this.isBusy = false
      }

    },

    // buscar roles de la aplicacion
    async updateRoles(){

      this.formModal.selectedRoles = []
      this.rolesOptions = []

      try {

        this.isBusy = true

        this.rolesOptions = await this.listRolesByApp( { sysId: this.formModal.selectedSystem } )

      } catch (error) {

        console.log("CATCH ROLES", error)

      } finally {
        this.isBusy = false
      }

    },

    // reset data modal
    resetModal(){
      this.formModal.selectedSystem = null
      this.formModal.selectedRoles = []
      this.formModal.textJustificacion = null
    },

    // agregar aplicacion/roles
    async handleAgregarAplicacion(){

      try {

        this.errors = []

        this.isBusy = true

        const formData = {
          frmId: this.formLocal.frmId,
          sysId: this.formModal.selectedSystem,
          frsJustificacion: this.formModal.textJustificacion,
          frsRoles: this.formModal.selectedRoles.join(','),
          frsTarea: FORM_TASK_CREATE
        }

        await this.createFormSystem( formData )

        await this.loadDataDetalles()

        this.showModal = false

        this.isBusy = false


      } catch (error) {

        if ( error.response.status == 422 ) {

          const  data  = error.response.data.errors

          console.log( "UNXPECTED", error.response.data )

          // errores de validacion
          this.errors = data

        }

      } finally {
        this.isBusy = false
      }


    },

    // quitar aplicacion de la lista
    async handleEliminarAplicacion(id){

      try {

        this.isBusy = true
        await this.deleteFormSystem( id )
        await this.loadDataDetalles()

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Formulario',
            icon: 'CheckIcon',
            text: '¡Registro fue eliminado!',
            variant: 'success'
          },
        })

      } catch (error) {

      } finally {
        this.isBusy = false
      }

    },

    // procesar peticion cerrar formulario
    handleCerrarModal(){
      this.resetModal()
      this.showModal = false
    },



  },

  async created() {

    await this.loadData()
    await this.loadDataDetalles()
    await this.initializeReferences()

    this.initializedData = true

  },

  mounted() {

  },


}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
@import '@core/scss/vue/libs/vue-flatpicker';
</style>
